import React from "react";
import Resource from "./resource";
import tw from "twin.macro";
import { motion } from "framer-motion";
import useResourceFilter from "./resource-filter-hook";

const StyledResourceList = tw.div`w-full md:px-20 lg:px-12 xl:px-60`;

const ResourceList = ({
	data,
	share,
	featuredCategory,
	resourceFeedbackData,
}) => {
	// console.log(data);

	const resourceData = data.nodes;
	const resources = useResourceFilter(featuredCategory, resourceData);

	const listAnimation = {
		hidden: { opacity: 0, y: 50 },
		visible: { opacity: 1, y: 0 },
	};

	return (
		<StyledResourceList>
			{resources.map((item) => {
				// console.log(item.title);
				return (
					<motion.div
						initial="hidden"
						animate="visible"
						variants={listAnimation}
						transition={{ duration: 0.6 }}
						layout
						key={item.title}
					>
						<Resource
							data={item}
							featuredCategory={featuredCategory}
							resourceFeedbackData={resourceFeedbackData}
							shareData={share}
						/>
					</motion.div>
				);
			})}
		</StyledResourceList>
	);
};

export default ResourceList;
